@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.MonitorFloorResourcePage_container {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;

	.contentContainer {
		overflow: auto;
		display: flex;
		flex-direction: column;
		width: 100%;
		padding: $monitorHeaderHeight 16px 16px 16px;
	}
}
