@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.locationsCountSummaryCardsSectionContainer {
	display: flex;
	flex-direction: column;

	.cardContainer {
		border-bottom: 1px solid $grey100;
	}

	.specialFloorsHeader {
		@include subtitle-semi();
		padding: 16px 0px;
	}
}
