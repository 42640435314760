@import '@shared/styles/variables';
@import '@shared/styles/mixins/text';
@import '@shared/styles/mixins/media_queries';

.generalCardsContainer {
	min-height: 83px;
	background-color: rgba(16, 48, 69, 0.03);
	border-bottom: 1px solid rgba(16, 48, 69, 0.2);

	.divider {
		border-left: 1px solid rgba(16, 48, 69, 0.3);
		margin: 17px 0;
	}

	.container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;

		.valueText {
			@include subtitle-reg();
			@include for-narrow-layout {
				@include body2;
			}
		}

		.descriptionText {
			@include body2;

			@include for-narrow-layout {
				@include small-regular;
			}
		}

		.iconValueContainer {
			display: flex;
			align-items: center;
		}
	}
}

.dashboardTabsSectionContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	min-height: 85px;
	max-height: 85px;
	border-bottom: 1px solid $grey100;
	border-top: 1px solid $grey100;
}
