@import '@shared/styles/mixins/text';
@import '@shared/styles/variables';

.activitiesContainer {
	background-color: $white;
}

.navbarContainer {
	border-top: 1px solid rgb(236 234 234);
	margin-bottom: 10px;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	padding: 0 1rem;
	height: 50px;
	@include body2();

	& > div {
		flex: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 9px 18px;
		border-radius: 8px;
		white-space: nowrap;
		overflow: hidden;

		img {
			margin: 0 2px;
		}
	}
}
.selected {
	position: relative;
	background-color: $grey50;
}

.completedSection {
	@include body2();
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
	white-space: nowrap;
	overflow: hidden;
	margin: 0 20px;
	padding: 5px 0;

	img {
		margin: 0 2px;
	}
}
