@import '../../../styles/variables.scss';
@import '../../../styles/main';

.notificationsContainer {
	background-color: $white;

	.emptyStateContainer {
		height: 100vh;
	}

	.cardsContainer {
		width: 100%;
		padding: 0 24px;

		.cardsSection {
			display: flex;
			flex-direction: column;
			gap: 16px;
			margin-top: 16px;

			.title {
				@include body2($grey400);
			}

			.divider {
				height: 1px;
				background-color: $grey100;
				width: 100%;
			}
		}
	}
}
