@import '@shared/styles/variables.scss';
@import '@shared/styles/mixins/text.scss';

.customizeHeader {
	background-color: $primaryColor;
	position: absolute;
	height: 40px;
	top: 57px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	z-index: 9;

	img {
		position: absolute;
		height: 24px;
		width: 24px;
		left: 20px;
		color: $white;
		filter: brightness(0) invert(1);
		top: 50%;
		transform: translateY(-50%);

		&.rtl {
			left: unset;
			right: 20px;
			transform: translateY(-50%) rotate(180deg);
		}
	}

	.customizeText {
		@include subtitle-reg();
		color: $white;
	}
}
