@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/_variables.scss';

.toastContainer {
	position: fixed;
	bottom: 10px;
	left: 0;
	right: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	z-index: 1000000000 !important;

	.toast {
		@include subtitle-reg($white);
		text-overflow: ellipsis;
		overflow: hidden;
		max-width: 100%;
		background: $darkBlue;
		border-radius: 8px;
		padding: 1rem;
		margin-bottom: 1rem;
		width: 80%;
		display: flex;
		align-items: center;
		animation: slide-up 0.3s ease-out;
		transition: opacity 0.3s ease-out;

		&.disappear {
			animation: slide-down 0.3s ease-out;
			opacity: 0;
		}

		.toastIcon {
			margin-inline-end: 11px;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
}

@keyframes slide-up {
	from {
		transform: translateY(100%);
	}
	to {
		transform: translateY(0);
	}
}

@keyframes slide-down {
	from {
		transform: translateY(0);
	}
	to {
		transform: translateY(100%);
	}
}
