@import '@shared/styles/variables';
@import '@shared/styles/mixins/text';

.superContainer {
	position: relative;
}

.headerClosed {
	@include subtitle-reg();
	z-index: 1;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 100%;
	user-select: none;
	border-radius: 3px;
	position: absolute;
	top: 0;
	right: 0;
}

.headerOpen {
	@include subtitle-reg();
	z-index: 11;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	user-select: none;
	border-radius: 3px;
	box-shadow: 0px 2px 0 0 rgba(0, 0, 0, 0.1);
	position: absolute;
	top: 0;
	right: 0;
}

.headerTitle {
	@include body2();
	margin-left: 6px;
	width: 100%;
	text-align: end;
}

.disabledTextInput {
	@include body2();
	margin-left: 6px;
	height: 17px;
	width: 100%;
	border-width: 0;
	color: #7b7b7b;
}

.arrow {
	height: 17px;
	width: 17px;
	margin-right: 6px;
}

.selectContainer {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
}

.listContainer {
	display: flex;
	flex-direction: column;
	max-height: 150;
	overflow-y: auto;
	margin-left: 12px;
	margin-right: 12px;
}

.headerContainer {
	display: flex;
	justify-content: space-between;
	margin-bottom: 4px;
	margin-top: 8px;
}

.checkRoot {
	width: 10px;
	height: 10px;
}

.listItemContainer {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding-right: 4px;
	padding-left: 4px;
	margin-bottom: 4px;
	&:hover {
		background-color: #effbd6;
	}
}
.listItem {
	@include body2(#5d5b5a);
}
.errorText {
	color: $feedbackCriticalDark;
	padding-right: 2px;
}
.errorContainer {
	height: 15px;
}
.container {
	display: flex;
	flex-direction: column;
	width: 100%;
}
