@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.ResourceCountCardsSectionContainer {
	display: flex;
	flex-direction: column;

	.resourceCountContainer {
		border-top: 1px solid $grey200;

		&:last-child {
			border-bottom: 1px solid $grey200;
		}
	}
}
