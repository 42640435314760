@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/utility_classes.scss';

.topDropdowns {
	margin-top: 10px;
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 100%;
}

.assignTagContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	width: 100%;
	padding: 0 20px;
	background-color: $white;
	justify-content: flex-start;
	margin-top: 40px;

	.idAndNameSection {
		display: flex;
		gap: 8px;
		width: 100%;
	}
}

.assignTagHeader {
	@include body2();
	height: 75px;
	display: flex;
	align-items: center;
	padding-inline-start: 24px;
}

.mobileInputContainer {
	margin-top: 12px;
	width: 100%;
}

.buttonsContainer {
	@include body2();
	position: absolute;
	display: flex;
	justify-content: space-between;
	padding: 20px;
	bottom: 0;
	height: 80px;
	width: 100%;
	background: $white;
	border-top: 1px solid rgba(0, 0, 0, 0.2);

	.primaryButton {
		width: 88px;
		height: 40px;

		&.disabledButton {
			background-color: $primaryColor;
			opacity: 0.5;
			color: $white;
			pointer-events: none;
		}
	}

	.secondaryButton {
		background-color: transparent;
		color: $primaryColor;
		margin-right: 16px;
	}
}
