@import '@shared/styles/mixins/text';

.textContainer {
	margin: 10px;
}

.welcomeText {
	@include h6();
	margin: 7px;
}

.pageText {
	@include body2();
	margin: 7px;
	white-space: pre-line;
}
