@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/media_queries';
@import '../../../../styles/variables';

.noOverflow {
	overflow: hidden;
}

.header {
	display: flex;
	align-items: center;
	width: 100%;
	height: $mobileHeaderMinHeight;
	background-color: $white;
	z-index: 99;
	position: relative;
	flex-direction: row;
	justify-content: center;
	border-bottom: 1px solid $grey200;
	overflow-y: hidden;

	.back {
		position: absolute;
		left: 20px;

		&.isRtl {
			left: unset;
			right: 20px;
		}
	}

	.title {
		@include h6;
	}
}

.content {
	padding: 24px;
	overflow-y: auto;
	height: calc(100svh - $mobileHeaderMinHeight);
	display: flex;
	flex-direction: column;
	gap: 24px;
}
