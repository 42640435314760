@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.searchContainer {
	position: absolute;
	z-index: 10;
	width: 100%;
	display: flex;
	flex-direction: column;
	background: $darkBlue;
	padding: 16px;

	.content {
		cursor: pointer;
		border-radius: 4px;
		border: 1px solid $blue200;
		display: flex;
		gap: 4px;
		padding: 4px 8px;
		background: $white;
	}

	.input {
		width: 100%;
		display: flex;
		align-items: center;
		background: $white;
		@include subtitle-semi();

		&::placeholder {
			@include subtitle-semi();
		}
	}
}

.wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.monitorPageContainer {
	overflow: auto;
	display: flex;
	flex-direction: column;
	gap: 16px;
	width: 100%;
	padding: $monitorHeaderHeight 16px 0px 16px;

	.topSection {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.topText {
			@include subtitle-semi();
		}
	}
}
