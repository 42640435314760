@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';
.fullContainer {
	&.makePlace {
		margin-top: 40px;
	}
	.setupPageContainer {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 20px;

		.setupText {
			@include h1();
			line-height: 1;
		}

		.selectViewText {
			@include subtitle-reg();
			align-self: flex-start;
			margin-top: 16px;
		}
	}

	.setupCardContainer {
		width: 100%;
		display: flex;
		flex-direction: column;
		padding: 16px;
		border-radius: 8px;
		background-color: $white;
		box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.16);
		margin: 16px 0;
		border: 2px solid transparent;

		&.isSelected {
			border: 2px solid $primaryColor;
		}

		.titleSection {
			display: flex;
			align-items: center;

			img {
				margin-inline-end: 8px;
			}

			.title {
				@include h6();
			}
		}

		.cardText {
			@include body2($grey500);
			margin: 16px 0;
		}

		.recommendedRoles {
			display: flex;

			.role {
				@include body2();
				border-radius: 3px;
				background: $grey100;
				padding: 4px 8px;
				margin-inline-end: 8px;
			}
		}
	}
}

.innerSection {
	margin: 16px 0;
}

.floorSelectionTitle {
	padding: 16px 20px;
	@include h6();
}

.title {
	@include h6();
	font-weight: 700;
	text-align: center;
}
