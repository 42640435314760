@import '@shared/styles/variables';
@import '@shared/styles/mixins/text';

.searchInput {
	height: 32px !important;
}

.bottomSection {
	overflow: scroll;
	padding: 8px;

	.isSelected {
		background-color: $grey50;
	}

	.managerItem {
		@include body1($grey600);

		display: flex;
		padding: 8px;
	}
}
