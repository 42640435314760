@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.container {
	z-index: 1;
	position: absolute;
	background: $white;
	width: 100%;
}

.endContainer {
	display: flex;
	align-items: center;
	gap: 8px;
}

.locationMonitorHeaderContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 8px 12px;
	background-color: $darkBlue;
	height: 48px;
	width: 100%;

	.headerText {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		@include subtitle-semi($white);
	}

	.plusIconContainer {
		background-color: $yellow;
		width: 24px;
		height: 24px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.plusIconEmpty {
		width: 24px;
		height: 24px;
	}
}

.breadCrumbsItems {
	display: flex;
	align-items: center;
	gap: 8px;
	padding: 0 16px;
}
