@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.tabsContainer {
	position: sticky;
	top: 0;
}

.monitorAreaPageContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;

	.contentContainer {
		padding-top: $monitorHeaderHeight;
		height: 100%;
		position: relative;
		display: flex;
		flex-direction: column;
		width: 100%;
		overflow: hidden;

		.content {
			overflow: auto;
		}

		.breadcrumbsContainer {
			padding: 0px 16px;
		}
	}
}
