@import '@shared/styles/mixins/text';

.amountText {
	@include h5();
	font-style: normal;
	margin: 3px;
}

.content {
	display: flex;
	align-items: center;
	height: 100%;
}

.empty {
	@include subtitle-reg();
	margin: 0;
}
