@import '@shared/styles/variables.scss';
@import '@shared/styles/mixins/text.scss';

.bottomActionsContainer {
	position: absolute;
	display: flex;
	padding: 20px;
	align-items: center;
	justify-content: center;
	bottom: 0;
	height: 80px;
	width: 100%;
	background: $white;

	.primaryButton {
		@include button-small;
		text-transform: uppercase;
		display: flex;
		color: $white;
		width: 100%;
		height: 48px;
		justify-content: center;
		align-items: center;
		align-self: stretch;
	}
}
