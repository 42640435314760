@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';
.issueCollapseContainer {
	border-radius: 8px;
	border: 1px solid $grey100;
	margin: 8px;

	.issuesItemsContainer {
		padding: 16px;
		background-color: $blue100;
		display: flex;
		flex-direction: column;
		gap: 8px;
	}

	.headerContainer {
		padding: 16px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.headerText {
			@include link();
		}

		.headerContractorText {
			@include subtitle-reg;
		}

		.infoSection {
			display: flex;
			align-items: center;
			p {
				@include subtitle-semi();
				margin: 0 8px;
			}
		}

		.arrow {
			transform: rotate(90deg);
			height: 16px;
			width: 16px;

			&.open {
				transform: rotate(270deg);
			}
		}
	}
}
