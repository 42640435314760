@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/utility_classes.scss';

.cardWrapper {
	display: flex;
	flex-direction: column;
	padding: 10px 10px;
	background: $white;
	border-radius: 4px;
}

.professionHeaderContainer {
	width: fit-content;

	.contractorHeader {
		@include subtitle-reg();
	}
}

.activityInnerCardWrapper {
	display: flex;
	flex-direction: column;
	gap: 2px;
	margin-top: 20px;
	background: $white;
	border-radius: 4px;
}

.activityInfoWrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.activityInfo {
	@include flex-center-row;
	margin-left: 10px;
	gap: 5px;
}

.activityDescription {
	@include subtitle-reg();
}

.iconWithNumber {
	@include flex-center-row;
	@include body2;
	gap: 3px;
}

.workHours {
	@include flex-center-row;
	@include body2;
	letter-spacing: -1px;
}

.h {
	font-weight: 300;
}

.activityDetails {
	@include flex-center-row;
	gap: 8px;
	margin-inline-start: 10px;
}

.threeDotsWrapper {
	display: flex;
	margin-inline-start: 10px;
	background: $grey100;
	height: 20px;
	width: 20px;
	border-radius: 4px;
}

.lineDivider {
	margin-top: 8px;
	border: 0.5px solid rgb(194 173 173 / 10%);
}
