@import '@shared/styles/variables';
@import '@shared/styles/mixins/text';

.drawerContainer {
	padding: 12px 0;

	.bodySection {
		overflow-y: auto;
	}
}

.customHeader {
	position: sticky;
}

.topSection {
	justify-content: space-between;
	align-items: center;
	align-self: start;
	display: flex;
	gap: 20px;
	width: 100%;
	position: sticky;
	top: 0;
	background-color: $white;
	padding-bottom: 8px;
}

.primaryButton {
	@include button-large($blue300);
}

.title {
	position: absolute;
	width: 100%;
	text-align: center;
	@include subtitle-semi($grey500);
	line-height: 28px;
}

.closeButton {
	z-index: 10;
}

.dialogPaper {
	border-radius: 8px;
	padding: 0 8px;
	overflow-y: hidden !important;
}

.dialogRoot {
	z-index: 10001 !important;
}

.dialogPaperAnchor {
	max-height: 90svh !important;
	min-height: 90svh !important;
}

.notScrollable {
	overflow-y: hidden !important;
	display: flex;
	flex-direction: column;
}
