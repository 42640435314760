@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';

.cardContainer {
	display: flex;
	flex-direction: column;
	padding: 16px 16px;
	border: 1px solid $grey200;
	border-radius: 8px;
	margin-bottom: 16px;

	.cardHeader {
		display: flex;
		align-items: center;

		.titleText {
			@include subtitle-semi();
			text-transform: capitalize;
			line-height: 21px;
		}

		img {
			margin-inline-end: 10px;
		}
	}

	.cardBody {
		@include body2();

		.singleCardSection {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			flex: 1;

			.textSection {
				@include small;
				line-height: 18px;
				margin: 30px 0 21px 0;

				.description {
					color: $grey600;
					font-weight: 500;
				}
			}

			& :global(.MuiInputBase-input) {
				padding-bottom: 12px !important;
				padding-top: 12px !important;
			}
			label {
				font-size: 13px !important;
			}

			.actions {
				display: flex;
				margin-top: 5px;
				flex-wrap: wrap;

				.switchContainer {
					display: flex;
					align-items: center;
				}
			}
		}
	}
}

.divider {
	width: 100%;
	height: 1px;
	background-color: $grey200;
	margin-top: 16px;
}
