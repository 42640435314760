@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';

$adHocCardWidth: 70px;

.adhoCardsContainer {
	display: grid;
	grid-column-gap: 24px;
	grid-template-columns: repeat(auto-fill, $adHocCardWidth);
	width: 100%;
	padding-top: 16px;
	padding-inline-start: 16px;

	.floorCard {
		height: 76px;
		width: $adHocCardWidth;
		padding: 8px 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 8px;

		.floorText {
			text-align: center;
			@include h6($grey600);
			line-height: 16px;
		}

		.floorHours {
			@include body1($grey400);
			line-height: 16px;
		}
	}
}
