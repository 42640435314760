@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.banner {
	padding: 16px;
	width: 100%;

	.bannerMainText {
		@include body1($white);
		display: flex;
		gap: 8px;
		align-items: center;
	}

	.bannerSubText {
		@include body2($white);
		margin-top: 8px;
	}
}

.offline {
	background-color: $feedbackCriticalDark;
}

.online {
	background-color: $feedbackPositiveDark;
}

.syncIcon {
	animation: spin 3s linear infinite;
}

@keyframes spin {
	from {
		transform: rotate(360deg);
	}
	to {
		transform: rotate(0deg);
	}
}
