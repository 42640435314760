@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.resourceCountCardContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 12px 8px;

	&.disabled {
		background-color: $grey50;
	}

	.innerContainer {
		display: flex;
		align-items: center;
		gap: 8px;

		&.isLoading {
			gap: 4px;
		}

		.text {
			@include button-small();

			&.disabled {
				color: $grey400;
			}
		}
	}
}
