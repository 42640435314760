@import '@shared/styles/variables';
@import '@shared/styles/mixins/text';

.searchContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: $white;
	width: 100%;
	height: 40px;
	border-radius: 3px;
	padding: 0 4px;
	position: relative;
}

.leftSection {
	width: 100%;
	display: flex;
	align-items: center;
}

.textInput {
	@include body1($primaryColor);
	border: none;
	font-weight: normal;
	line-height: 0;
	width: 100%;
	margin-top: 2px;

	&:focus {
		outline: none;
	}
}

.searchIcon {
	width: 32px;
	height: 32px;
	margin: 0 4px;
}

.clearIcon {
	width: 32px;
	height: 32px;
	padding-inline-end: 6px;
}
