@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';

.educationContainer {
	position: absolute;
	z-index: 3;
	left: 50%;
	transform: translateX(-50%);
	width: 335px;
	padding: 16px;
	border-radius: 8px;
	background: #2cffcb;

	.title {
		@include subtitle-semi($primaryColor);
		margin: 16px 0;
	}

	.descriptionText {
		@include small-regular($primaryColor);
		margin-bottom: 16px;
	}

	.closeIcon {
		background-color: $primaryColor;
		border-radius: 50%;
		padding: 2px;
		position: absolute;
		top: 16px;
		right: 16px;

		&.rtl {
			left: 16px;
			right: unset;
		}
	}

	.triangle {
		top: -10px;
		right: 50%;
		transform: translateX(-50%);
		position: absolute;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 8px 10px 8px;
		border-color: transparent transparent #2cffcb transparent;
	}
}

.blackScreen {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 2;
}
