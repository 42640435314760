@import '@shared/styles/variables';
@import '@shared/styles/mixins/text';

.floorNick {
	@include subtitle-semi();
	padding: 16px 0;
}

.areaNick {
	@include button-small;
}

.contractorHeader {
	@include h6();
}

.content {
	padding: 0 20px 16px 20px;
	display: flex;
	flex-direction: column;

	.contentBody {
		padding: 0 8px;
		display: flex;
		flex-direction: column;
		gap: 16px;

		.areaSection {
			margin-top: 8px;

			.cardsContainer {
				margin-top: 8px;
			}
		}
	}
}

.content:before {
	content: '';
	border-top: 1px solid $grey200;
	width: 100%;
	left: 0;
	position: absolute;
}

.card {
	display: flex;
	justify-content: space-between;
	border-top: 1px solid $grey200;
	padding: 16px 8px;
}

.card:last-child {
	border-bottom: 1px solid $grey200;
}

.tagNick {
	@include link();
}

.name {
	@include body1($grey700);
}
