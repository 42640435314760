@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.FloorActivitiesPage_container {
}

.title {
	padding: 16px 0;
	@include subtitle-semi();
}

.content {
	display: flex;
	flex-direction: column;
	gap: 16px;
}
