@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.activityCard {
	position: relative;
	cursor: pointer;
	width: 100%;
	border: solid 1px $grey200;
	border-radius: 4px;
	background-color: $white;

	&:hover {
		border: 1.5px solid $darkBlue;
		box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.12);
	}
}
