@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';

.progressCardsContainer {
	display: flex;

	.sequenceProgressCardsContainer {
		display: grid;
		grid-gap: 5px;
		justify-content: center;
		align-content: center;
		width: 100%;
		flex: 1;
	}

	.activityProgressCardsContainer {
		border: 1px solid $grey100;
		border-radius: 8px;
		display: grid;
		grid-gap: 5px;
		width: 100%;
		justify-content: space-evenly;

		.activityProgressCard {
			height: 58px;
			padding: 10px 20px;
			@include small-regular();
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			.floorText {
				font-weight: 500;
				font-size: 12px;
				line-height: 18px;
				color: $grey700;
			}

			.timeText {
				font-weight: 500;
				font-size: 11px;
				line-height: 16px;
				color: $grey400;
			}
		}
	}
}

.areaSequenceItemContainer {
	@include small-regular();
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1;
	flex-direction: column;
	background-color: $grey50;
	padding: 8px 0;
	border-radius: 8px;
	position: relative;
	min-width: 50px;

	&.done {
		background-color: lighten($feedbackPositiveDefault, 15%);
	}

	.areaText {
		@include small();
		line-height: 18px;
	}

	.timeText {
		color: $grey500;
	}

	img {
		position: absolute;
		top: 5px;
		right: 5px;
	}
}
