@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.breadCrumbsContainer {
	display: flex;
	align-items: center;
	gap: 4px;
	padding: 8px 0;

	.breadCrumbItem {
		display: flex;
		align-items: center;

		.linkTitle {
			@include button-small($grey500);

			&.selected {
				color: $darkBlue;
			}
		}
	}
}
