@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';

.container {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 24px;
}
