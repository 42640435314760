@import '../shared/styles/variables';
@import '../shared/styles/mixins/text';

.container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.description {
	display: flex;
	@include subtitle-semi();
	gap: 2px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.endAligmentPart {
	display: flex;
	align-items: center;
	gap: 8px;
}

.endDate {
	display: flex;
	align-items: center;
}
