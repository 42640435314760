@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';

.activitiesPageHeader {
	display: flex;
	flex-direction: column;
}

.quickFiltersContainer {
	display: flex;
	gap: 8px;
	overflow: scroll;
	padding-inline-start: 20px;
	padding-inline-end: 20px;
	padding-bottom: 12px;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.quickFiltersContainer::-webkit-scrollbar {
	display: none;
}

.hr {
	border-top: 1px solid $grey200;
	margin: 0;
}
