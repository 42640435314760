@import '@shared/styles/mixins/text';

.pageWrapper {
	margin-top: 40px;
}

.pageTitle {
	@include subtitle-semi();
	padding: 10px 0px 0px 15px;
}

.sectionTitle {
	margin: 0 16px;
	padding: 16px 0;
	@include h6($grey500);

	&.isSelected {
		border-bottom: 3px solid $primaryColor;
		color: $primaryColor;
	}
}

.selectorWrapper {
	margin-top: 15px;
	display: flex;
	gap: 24px;
}

.selectionSection {
	display: none;

	&.isVisible {
		display: initial;
	}
}
