@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';

.clickableCardContainer {
	display: flex;
	color: $primaryColor;
	border: 1px solid $grey100;
	border-radius: 4px;
	background-color: $white;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	margin: 3px 0;
	min-height: 40px;
	padding: 12px 6px;
	height: 75px;

	&.selected {
		border: 2px solid $primaryColor;
	}

	.totalNumber {
		@include subtitle-reg();
		margin-inline-end: 16px;
	}

	.textContainer {
		display: flex;
		align-items: center;
		flex-grow: 1;
		max-width: 90%;
	}

	p {
		margin: unset;
		line-height: 14.6px;
	}

	.iconContainer {
		margin: 0 14px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.longTextContainer {
		display: flex;
		width: 100%;

		.longTextLeftContainer {
			display: flex;
			max-width: calc(0.65 * $sideMainComponentWidth);

			p {
				text-transform: capitalize;
			}
		}

		span {
			color: $grey600;
			line-height: 1;
		}

		.middleLongText {
			font-weight: 300;
			color: $grey600;
			line-height: 1;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}

.shorTextContainer {
	.contractorText {
		@include subtitle-semi();
	}

	.cardText {
		display: flex;
		@include small-regular;
		align-items: center;

		.professionText {
			display: flex;
			padding: 4px 8px;
			border-radius: 4px;
			align-items: center;
			justify-content: center;
			width: fit-content;
			color: $white;
		}

		.specialtyText {
			display: flex;
			padding: 4px 8px;
			align-items: center;
			border-radius: 4px;
			border: 1px solid $grey300;
			margin-inline-start: 6px;
		}
	}

	width: 100%;
	@include for-narrow-layout {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}

.greyText {
	margin: unset !important;
	color: $grey600;
}

.normalText {
	margin: unset !important;
	color: $primaryColor;
}

.cardText {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
