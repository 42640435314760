@import '../../styles/variables';
@import '@shared/styles/variables';
@import '@shared/styles/mixins/text';

.floorRow {
	@include body2;
	display: flex;
	align-items: center;
	justify-content: space-between;
	text-transform: capitalize;
	padding: 12px 8px;
}

.workersCount {
	@include subtitle-semi();
}

.floorNick {
	@include body2();
}

.borderBottom {
	&:not(:last-child) {
		border-bottom: 0.5px solid $rowBorderColor;
	}
}

.rightSection {
	display: flex;
	align-items: center;
	gap: 16px;
}
