@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.titleWrapper {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.optionSections {
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;
}

.selectOptions {
	display: flex;
	flex-direction: column;
	gap: 18px;
	padding: 12px 20px;
	width: 100%;
}

.actionButton {
	text-decoration: underline;
	@include body2();
}

.selectSection {
	padding: 16px 20px;
}

.option {
	display: flex;
	gap: 16px;
}

.optionWrapper {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.title {
	@include link();

	&.disabled {
		pointer-events: none;
		color: $grey500;
	}
}

.sectionTitle {
	@include body2($grey500);
}
