@import '@shared/styles/variables';
@import 'src/styles/variables';

.headers {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 0.5px solid $rowBorderColor;
}

.professionWorkerDataContainer {
	background-color: $white;
	margin-bottom: 8px;
	padding: 6px;
}

.borderStart {
	border: 1px solid $grey200;
	border-radius: 8px;
}

.inactive {
	opacity: 0.5;
}
