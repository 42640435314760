@import '@shared/styles/variables';
@import '@shared/styles/mixins/text';

.bottomSection {
	overflow: scroll;
	padding: 8px;
	.sectionName {
		width: 100%;
		@include small;
		padding: 12px;
		border-top: 1px solid $grey400;
		border-bottom: 1px solid $grey400;
	}

	.areaItem {
		display: flex;
		align-items: center;
		padding: 12px;
		@include body2($grey600);
	}
}
