@import '@shared/styles/variables';
@import '../../styles/variables';

$mobileLogosContainerWidth: 114px;

.header {
	display: flex;
	align-items: center;
	width: 100%;
	height: $mobileHeaderMinHeight;
	background-color: $primaryColor;
	z-index: 99;
	position: sticky;
}

.projectSelectorContainer {
	z-index: 1000;
	width: calc(100% - $mobileLogosContainerWidth);
}

.logosContainer {
	width: $mobileLogosContainerWidth;
	display: flex;
	align-items: center;
}

.projectLogo {
	width: 60px;
	height: 20px;
}
