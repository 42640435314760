@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.multiViewContainerPage {
	position: fixed;
	z-index: 999;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100%;
	width: 100vw;
	background-color: $white;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}

.topSection {
	display: flex;
	align-items: center;
	width: 100%;
	min-height: 64px;
	padding: 0px 20px;
	border-bottom: 1px solid $grey100;
}

.activityHeader {
	padding: 16px;

	.statusBox {
		display: flex;
		padding: 6px 4px;
		align-items: center;
		width: fit-content;
		border-radius: 4px;
		@include small($grey700);
	}
}

.infoSection {
	display: flex;
	flex-direction: column;
	gap: 18px;
	margin-top: 14px;
	margin-bottom: 18px;

	.description {
		@include h6($darkBlue);
	}

	.professionSection {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 8px;

		img {
			height: 16px;
			width: 16px;
		}

		.professionText {
			@include body2($grey700);
		}
	}
}

.issuesContainer {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	background-color: $blue100;
	padding: 16px;
	gap: 16px;
	height: calc(100% - 65px);

	.issueSwitcher {
		@include subtitle-semi($darkBlue);
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		.arrow {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 24px;
			width: 24px;
		}
	}

	.issueContainer {
		background-color: $white;
		border-radius: 8px;
		padding: 16px;
		height: calc(100% - 105px);
	}
}
