@import '@shared/styles/variables';
@import '@shared/styles/mixins/text';

.headerContainer {
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid $grey100;
	gap: 8px;
	padding: 8px;
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;

	.description {
		@include body1();
		flex-shrink: 1;
	}

	.headerEndSection {
		display: flex;
		align-items: center;
		gap: 4px;
		flex-shrink: 0;
	}
}
