@import '@shared/styles/mixins/text';
@import '@shared/styles/variables';

.profileContainer {
	background-color: $white;
	border-radius: 4px;
	padding: 10px;
	margin: 15px 8px;

	.userDescription {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-bottom: 20px;

		& > img {
			width: 100px;
			height: 100px;
			filter: invert(77%) sepia(63%) saturate(580%) hue-rotate(160deg) brightness(85%) contrast(60%);
		}

		.userName {
			@include subtitle-semi();
			text-align: center;
		}
	}

	.userLinks {
		cursor: pointer;
		width: 100%;

		.userLink {
			@include body2();

			.arrow {
				height: 20px;
				width: 20px;

				&.leftArrow {
					transform: rotate(270deg);
				}

				&.rightArrow {
					transform: rotate(90deg);
				}
			}

			display: flex;
			align-items: center;

			&.border {
				border-bottom: 1px solid $grey50;
			}

			width: 100%;
			height: 50px;

			img {
				height: 20px;
				width: 20px;
				margin: 0 10px;
			}

			.secondText {
				width: max-content;
				@include subtitle-reg($grey500);
			}
		}
	}
}
