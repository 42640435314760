@import '@shared/styles/variables';
@import '@shared/styles/mixins/text';

.fullPage {
	background: $white;
	height: 100vh;
	overflow: auto;
	z-index: 10000 !important;
	position: fixed;
	width: 100%;
	left: 0;
	right: 0;
	top: 0;
}
