@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.divider {
	width: 100%;
	height: 1px;
	background-color: $grey200;
	margin-top: 16px;
}

.notificationsPageContainer {
	display: flex;
	flex-direction: column;

	.topContainer {
		width: 100%;
		position: relative;
		justify-content: center;
		align-items: center;
		display: flex;
		height: 50px;
		margin-top: 16px;

		img {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 16px;

			&.rtl {
				left: auto;
				right: 16px;
				transform: translateY(-50%) rotate(180deg);
			}
		}

		.title {
			@include h6();
			display: flex;
			align-items: center;
			justify-content: center;
			text-transform: capitalize;
		}
	}

	.cardsContainer {
		display: flex;
		flex-direction: column;
		margin: 56px 16px 16px 16px;

		.enablePushNotificationsCard {
			padding: 16px 0px;
			border-radius: 8px;
			border: 1px solid $grey200;
			margin-bottom: 16px;
		}
	}
}
