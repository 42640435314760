@mixin flex-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

@mixin flex-center-column {
	@include flex-center;
	flex-direction: column;
}

@mixin flex-center-row {
	@include flex-center;
	flex-direction: row;
}

@mixin flex-between {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
