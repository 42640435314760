@import '@shared/styles/mixins/text';

.ManagerFloorCard_container {
	display: flex;
	justify-content: space-between;
	padding: 16px;
	@include body2();
	border-top: 0.5px solid rgba(22, 22, 22, 0.1);
}

.managerRole {
	background: $grey100;
	padding: 4px 8px;
	border-radius: 3px;
}
