@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.monitorAreaActivityCardContainer {
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding: 8px 12px;

	.activityDescription {
		@include body1();
	}

	.professionAndStatus {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

.changeStatusText {
	@include subtitle-semi();
	padding-inline-start: 20px;
}

.statusUpdatesList {
	display: flex;
	flex-direction: column;
	gap: 24px;
	padding-top: 24px;

	.statusUpdateItem {
		display: flex;
		align-items: center;
		gap: 8px;
		padding: 0px 16px;

		.radioItem {
			width: 16px;
			height: 16px;
			border-radius: 50%;
			border: 1px solid $grey200;
		}
	}
}
