@import '@shared/styles/variables';
@import '@shared/styles/mixins/text';

.cardContainer {
	width: 100%;

	.innerCardContainer {
		flex: 1;
		width: 100%;
		justify-content: space-between;
		display: flex;

		.nameContainer {
			display: flex;
			flex-direction: column;
			padding-bottom: 8px;
			flex: 1;
			max-width: 50%;
			p {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				&.type {
					@include body2();
				}
				&.serialNumber {
					@include small-regular;
					color: $grey600;
				}
			}
		}

		.lastSeenContainer {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			padding-bottom: 8px;
			justify-content: space-between;
			flex: 1;
			position: relative;
		}
		.lastSeenDate {
			@include small-regular($grey400);
		}

		.notAvailable {
			@include small-regular($grey400);
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}

		.floorChip {
			@include small-regular($grey400);
			border-radius: 12px;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			width: fit-content;
		}
	}
}

.location {
	display: flex;
	gap: 2px;
}
