@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '../../../styles/variables';

.issuesPageContainer {
	display: flex;
	flex-direction: column;
	flex: 1;
	height: 100%;

	.header {
		@include h6();
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 20px 16px;
		border-bottom: 1px solid $grey100;
	}
}

.issuesFilter {
	position: fixed;
	height: 100vh;
	width: 100vw;
}

.addIssueButton {
	cursor: pointer;
	position: fixed;
	background-color: $yellow;
	border-radius: 50%;
	padding: 6px;
	width: 56px;
	box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.12);
	bottom: calc($navBarHeight + 8px);
	right: 8px;

	&.rtl {
		left: 8px;
		right: unset;
	}

	&.marginBottom {
		bottom: calc($navBarHeight + 8px + 15px);
	}
}
