@import '@shared/styles/mixins/text';
@import '@shared/styles/variables';

.container {
	width: 100%;
	margin: 0 auto;

	.equipmentCardContainer {
		margin-top: 16px;
		border: 1px solid $grey200;
		width: 100%;
		border-radius: 16px;
		padding: 16px;

		.groupTitle {
			@include subtitle-reg();
			margin: 8px 0;
		}

		.groupLength {
			@include h5();
		}
	}
}

.titleSection {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	border-bottom: 1px solid $grey200;
	margin-bottom: 10px;
	padding-bottom: 5px;
}

.cardsContainer {
	display: flex;
	flex-direction: column;
	padding: 0 20px;
}

.divider {
	border-bottom: 1px solid $grey100;
}
