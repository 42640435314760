@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';

.viewContainer {
	align-items: center;
	position: relative;
	padding: 12px;
	border-radius: 16px;
	border: 1px solid $grey200;
	background: $white;
	box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.16);
	margin: 20px;

	&.makeSpace {
		padding-bottom: 40px;
	}

	&.increaseLayer {
		z-index: 3;
	}
	.preferencesContentContainer {
		justify-content: space-between;
		display: flex;
		.countSection {
			display: flex;
			flex-direction: column;
			justify-content: center;

			.countNumber {
				display: flex;
				align-items: center;
				@include h5($primaryColor);

				.outOfText {
					@include small-regular($primaryColor);
					margin-inline-start: 4px;
					white-space: nowrap;
				}
			}

			.countText {
				@include small-regular($grey500);
				line-height: 16px;
				text-transform: uppercase;
			}
		}

		.iconContainer {
			display: flex;
			align-self: center;
			justify-content: flex-end;
		}
	}
}
