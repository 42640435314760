@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';

.customizeViewInfo {
	@include small-regular($grey600);
	position: absolute;
	bottom: 0;
	left: 0;
	display: flex;
	padding: 4px 16px;
	justify-content: space-between;
	background: $blue100;
	border-radius: 0 0 18px 18px;
	width: 100%;

	.infoSection {
		display: flex;
		align-items: center;
		.text {
			text-transform: uppercase;
			margin-inline-end: 24px;
		}
	}

	.explainIcon {
		@include small($grey600);
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
