@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';

.floorsSelectionContainer {
	display: flex;
	flex-direction: column;
	padding: 16px 20px;

	.floorCard {
		border-radius: 8px;
		border: 1px solid $grey200;
		display: flex;
		align-items: center;
		height: 65px;
		padding: 0 16px;
		margin-bottom: 8px;

		&.isSelected {
			border: 2px solid $primaryColor;
		}

		.floorCardText {
			@include subtitle-semi();
			margin-inline-start: 16px;
		}
		.counterText {
			@include subtitle-reg();
			margin-inline-start: auto;
		}
	}
}
