@import '@shared/styles/mixins/text';
@import '@shared/styles/mixins/utility_classes';
@import '@shared/styles/variables';

.section {
	background: $white;
	margin: 15px auto;
	border-radius: 4px;
	width: 95%;
	&.fullHeight {
		height: 100vh;
	}
}

.cardWrapper {
	margin-bottom: 8px;
}
