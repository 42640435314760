@import '@shared/styles/mixins/text';
@import '@shared/styles/variables';

.activitiesContainer {
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-top: 16px;
	margin-right: 8px;
	margin-left: 8px;
}
