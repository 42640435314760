@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.monitorAreaActivitiesContainer {
	padding: 8px 16px;
	display: flex;
	flex-direction: column;

	.sequenceTitle {
		@include subtitle-semi();
	}

	.activitiesSectionsContainer {
		display: flex;
		flex-direction: column;
	}
}

.dropdownInput {
	font-size: 16px !important;
}
