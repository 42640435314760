@import '@shared/styles/variables';
@import '@shared/styles/mixins/text';

.ProfessionTagsCard_container {
	display: flex;
	flex-direction: column;
	border-radius: 8px;
	border: 1px solid $grey200;
}

.title {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px;
	border-bottom: 1px solid $grey200;
}

.length {
	@include caption();
	padding-inline-end: 8px;
}

.content {
	padding: 0 8px;

	& > div {
		border-bottom: 1px solid $grey200;

		&:last-child {
			border-bottom: none;
		}
	}
}

.tagRow {
	display: flex;
	justify-content: space-between;
	padding: 12px 8px;

	.name {
		@include body2($grey700);
	}

	.tagNick {
		@include small();
	}

	&.isLastRow {
		border-bottom: none;
	}
}
