@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.contentContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 16px;
	padding: 0 20px;

	.header {
		display: flex;
		align-items: center;
		gap: 8px;
		margin-bottom: 16px;

		.pushNotificationText {
			@include h6;
			margin: 0;
		}
	}

	.enablePushText {
		@include body1();
	}
}

.fullWidthButton {
	width: 100%;
}

.textAlignStart {
	text-align: start;
}
