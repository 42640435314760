@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.wrapper {
	position: relative;
	display: flex;
	cursor: pointer;
}

.image {
	display: contents;
}

.menuWrapper {
	position: absolute;
	top: 30px;
	right: 0px;
	width: max-content;
	z-index: 100000;

	&.isRtl {
		right: unset;
		left: 0px;
	}
}
