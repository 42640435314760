@import '@shared/styles/_variables.scss';
@import 'src/styles/variables';
@import '@shared/styles/mixins/_text.scss';

.disabledColor {
	color: rgba($primaryColor, 0.5) !important;
}

.hide {
	visibility: hidden;
}

.halfOpacity {
	opacity: 0.5;
}

.card {
	background-color: $white;
	border: 1px solid #cfd2d6;
	border-radius: 8px;
}

.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-left: 8px;
	padding-right: 16px;
	@include small-regular();
	height: 34px;

	.description {
		display: flex;
		align-items: center;
		gap: 8px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		max-width: 75%;
	}

	.headerTitle {
		@include subtitle-semi();
		max-width: 75%;
		word-break: break-word;
		line-height: 24px;
	}

	.workerIcon {
		width: 10px;
		height: 10px;
	}

	.disabledOnSite {
		color: rgba($primaryColor, 0.5);
	}
}

.separator {
	height: 1px;
	background-color: $rowBorderColor;
}

.body {
	display: flex;
	flex-direction: column;
	padding: 8px 16px 8px 8px;
	min-height: 90px;

	.topBody {
		width: 100%;
		display: flex;
		justify-content: space-between;
		margin: 5px 0;

		.leftSection {
			display: flex;
			flex-direction: column;
			gap: 8px;
			max-width: 75%;

			.professionContainer {
				display: flex;
				justify-content: center;
				align-items: center;

				.tradeChip {
					@include small($white);
					border-radius: 3px;
					padding: 4px 5px;
					width: max-content;
					display: flex;
					justify-content: center;
					align-items: center;
					margin-inline-end: 5px;

					&.specialtyChip {
						border: 1px solid $grey200;
						color: $primaryColor;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}

				.contractorText {
					@include small($primaryColor);
				}
			}
		}

		.rightSection {
			display: flex;
			flex-direction: column;
			gap: 8px;

			.headerOnSite {
				@include small();
				display: flex;
				align-items: center;
				gap: 2px;
				line-height: 20px;
			}
		}
	}

	.progressContainer {
		display: flex;
		flex-direction: column;
		gap: 8px;
		margin-top: 10px;

		.progressHeaders {
			display: flex;
			justify-content: space-between;

			.titleIcon {
				display: flex;
				align-items: center;
				gap: 4px;
			}
			.title {
				@include small($primaryColor);

				&.disabledColor {
					color: $grey400;
				}
			}
		}
	}
}

.actionsFooter {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 20px;
	height: 45px;
	border-top: 1px solid #e7e9eb;

	.button {
		@include body2();
		text-align: center;
		line-height: 30px;
		border-radius: 4px;
		height: 29px;
		width: 77px;
	}

	.actionButton {
		color: $white;
		background-color: $primaryColor;
	}

	.issueButton {
		@include body2($grey600);
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: $white;
		gap: 4px;
		width: 115px;
	}
}

.commentContainer {
	padding: 8px;
}
