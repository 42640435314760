@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';

.activitiesPage {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.activitiesPageBody {
	padding: 10px 8px;
	display: flex;
	flex-direction: column;
	gap: 8px;
	overflow-y: scroll;
	flex-grow: 1;
}
