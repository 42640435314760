@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';

.drawerContainer {
	min-height: 30vh;
	max-height: 80vh;

	.topSection {
		display: flex;
		align-items: center;
		border-bottom: 1px solid $grey200;
		min-height: 52px;

		&.isTitleString {
			padding: 16px 20px;
		}

		.title {
			@include subtitle-semi();
		}

		.closeIcon {
			position: absolute;
			right: 20px;

			&.rtl {
				left: 20px;
				right: unset;
			}
		}
	}

	.bodySection {
		overflow-y: auto;
	}
}

.dialogPaper {
	border-radius: 8px;
}

.noBorder {
	border: none !important;
}

.noTopSection {
	margin-top: 32px !important;
}
