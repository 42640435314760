@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';

.chipContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid transparent;
	border-radius: 52px;
	padding-inline-start: 4px;
	width: max-content;
	min-height: 20px;

	.iconTextContainer {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 4px;

		.circle {
			width: 10px;
			height: 10px;
			border-radius: 50%;
			border: 1px solid $darkBlue;
			margin-inline-start: 5px;
		}

		.text {
			@include small();
			width: max-content;
		}

		.noIconText {
			margin-inline-start: 10px;
		}
	}

	&.border {
		border: 1px solid $grey600;
	}

	&.noDropdown {
		padding-inline-end: 12px;
	}
}
