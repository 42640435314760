@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.monitorAreaActivitiesSectionContainer {
	display: flex;
	flex-direction: column;

	.expandSection {
		display: flex;
		align-items: center;
		padding: 8px 0px;

		.titleText {
			@include button-small();
		}
	}

	.activitiesContainer {
		display: flex;
		flex-direction: column;
		border: 1px solid $grey100;
		border-radius: 8px;

		.activityContainer {
			border-bottom: 1px solid $grey100;

			&:last-child {
				border-bottom: none;
			}
		}
	}
}
