@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/media_queries';
@import '../../../../styles/variables';

.header {
	display: flex;
	align-items: center;
	width: 100%;
	height: $mobileHeaderMinHeight;
	background-color: $primaryColor;
	z-index: 99;
	position: relative;
	flex-direction: row;
	justify-content: center;
	padding: 0 8px;

	.backArrow {
		position: absolute;
		left: 12px;

		&.isRtl {
			left: unset;
			right: 12px;
		}
	}

	.title {
		@include subtitle-reg($white);
	}
}

.buttonContainer {
	display: flex;
	padding: 4px 12px;
}

.createButton {
	width: 94%;
	position: absolute;
	right: 12px;

	&.isRtl {
		right: unset;
		left: 12px;
	}
}

.content {
	height: calc(100% - $mobileHeaderMinHeight);
	display: flex;
	flex-direction: column;
	gap: 24px;
	padding: 12px;
}

.titleSection {
	display: flex;
	flex-direction: column;

	.titleInput {
		all: unset;
		@include subtitle-semi($grey600);
		line-height: 32px;
		border-radius: 4px;
		padding: 0 8px;

		&:focus {
			background-color: $grey50;
		}
	}
}

.descriptionInput {
	all: unset;
	@include subtitle-reg($grey400);
	line-height: 24px;
}

.selectWrapper {
	gap: 8px;
	display: flex;
	flex-direction: column;
}

.locationsSelected {
	display: flex;

	.floorText {
		@include small-regular($darkBlue);
	}
}

.assigneeName {
	@include small($grey400);
}

.selectIcon {
	width: 24px;
	height: 24px;
}
