@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.floorPlanPointsSelectorContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 8px;
}

.svg {
	width: 100%;
}

.topSection {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 6px 12px;
}

.floorText {
	@include button-large();
}

.floorPlanContainer {
	position: relative;
	width: 100%;
}

.pinPoint {
	position: absolute;
	transform: translate(-50%, -50%);
	pointer-events: none;
}

.instructionsText {
	@include subtitle-semi($white);
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(0, 0, 0, 0.6);
	height: 80px;
	width: 100%;
	gap: 8px;
	position: absolute;
	top: calc(50% - 40px);
	pointer-events: none;
}

.bottomSection {
	display: flex;
	justify-content: center;
	align-items: center;
}

.loaderContainer {
	padding-top: 14px;
	width: 100%;
	overflow: hidden;
}

.errorContainer {
	padding-top: 14px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.backButton {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	padding-inline-start: 20px;
}
