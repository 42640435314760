@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.activityActionsContainer {
	position: fixed;
	bottom: 0;
	height: 160px;
	width: 100%;
	background-color: $white;
	padding: 16px;
	box-shadow: 0px -2px 2px rgba(168, 156, 156, 0.1);
	z-index: 1;

	.closeIcon {
		position: absolute;
		right: 16px;
	}

	.closeIconRTL {
		position: absolute;
		left: 16px;
	}

	.professionContainer {
		display: flex;
		flex-direction: row;

		.professionDescription {
			@include small();
			margin-inline-start: 4px;
		}

		.professionText {
			@include small-regular();
			margin-inline-start: 8px;
		}
	}

	.actionsContainer {
		padding: 16px 0;
		display: flex;
		flex-direction: column;
		gap: 16px;

		.lineDivider {
			border: 1px solid $grey50;
		}

		.actionText {
			@include body2();
		}
	}
}
