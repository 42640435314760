@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';
@import '../../../../../styles/variables';

.drawerContainer {
	display: flex;
	flex-direction: column;
}

.header {
	display: flex;
	align-items: center;
	width: 100%;
	min-height: $mobileHeaderMinHeight;
	background-color: $primaryColor;
	z-index: 99;
	position: relative;
	flex-direction: row;
	justify-content: flex-end;
	padding: 0 16px;
	gap: 8px;

	.backArrow {
		position: absolute;
		left: 12px;

		&.isRtl {
			left: unset;
			right: 12px;
		}
	}
}

.content {
	height: calc(100% - $mobileHeaderMinHeight);
	display: flex;
	flex-direction: column;
	overflow: hidden;

	.descriptionHeader {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 12px;
		padding: 16px;
		border-bottom: 1px solid $grey200;

		.description {
			@include body1();
		}
	}

	.iconsHeader {
		display: flex;
		justify-content: space-between;
		padding: 16px;
		border-bottom: 1px solid $grey200;
		height: 40px;
		align-items: center;

		.startSection {
		}

		.endSection {
			display: flex;
			gap: 8px;

			.issueIcon {
				@include button-small($grey600);
				display: flex;
				align-items: center;
				height: 24px;
				cursor: pointer;
			}

			.asisInfo {
				@include button-small($grey600);
				display: flex;
				align-items: center;
				height: 24px;
				cursor: pointer;
			}
		}
	}
}
