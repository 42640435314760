@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/media_queries';

.datePickerContainer {
	:global(.react-datepicker) {
		width: 100% !important;
		box-shadow: none !important;
	}

	:global(.react-datepicker__day) {
		width: 45px !important;
		height: 45px !important;
		line-height: 2.9rem !important;
	}

	:global(.react-datepicker__day-name) {
		margin: 8px 6px !important;
	}

	:global(.react-datepicker__month-container) {
		float: unset !important;
	}

	:global(.react-datepicker__day:not(.react-datepicker__day--disabled):hover) {
		background-color: $primaryColor !important;
		color: $white !important;
		border-radius: 50% !important;
	}
}

.selectSection {
	height: 38px;
	cursor: pointer;
	border-radius: 4px;
	border: 2px solid #d5d5d5;
	display: flex;
	align-items: center;
	padding: 8px;
	overflow: hidden;

	gap: 4px;

	& .text {
		@include small($grey400);
		line-height: 16px;
	}
}

.selectIcon {
	width: 24px;
	height: 24px;
}

.drawerHeight {
	max-height: 65svh !important;
	min-height: 65svh !important;
}
