@import '@shared/styles/variables';
@import '@shared/styles/mixins/text';

.body {
	padding: 8px;

	.bodyHeader {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

.infoContainer {
	@include button-small($grey600);
	display: flex;
	align-items: center;
	height: 24px;
	cursor: pointer;
}

.bottomRight {
	display: flex;
	align-items: center;
	gap: 8px;
}
