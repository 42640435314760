@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.issue {
	border: 1px solid $grey200;
	border-radius: 8px;
}

.issuesContainer {
	display: flex;
	flex-direction: column;
	gap: 16px;
}
