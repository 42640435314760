@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';

.managersContainer {
	border: 1px solid $grey100;
	border-radius: 8px;
	background-color: #ffffff;
	margin-bottom: 8px;

	.title {
		display: flex;
		justify-content: space-between;
		border-bottom: 0.5px solid rgba(22, 22, 22, 0.1);
		padding: 0 8px;
		align-items: center;
		min-height: 50px;

		.titleText {
			text-transform: capitalize;
			@include subtitle-semi();
		}

		.managerLength {
			@include h5($primaryColor);
		}
	}

	.floorContainer {
		display: flex;
		padding: 12px 16px;

		&:not(:last-child) {
			border-bottom: 0.5px solid rgba(22, 22, 22, 0.1);
		}

		.floorTitle {
			@include body2;
			display: flex;
			flex: 1;
		}

		.floorManagers {
			@include body2($grey600);
			display: flex;
			flex: 1;
			flex-direction: column;

			.managerName {
				&:not(:last-child) {
					margin-bottom: 4px;
				}
			}
		}
	}
}
