@import '@shared/styles/variables';
@import '@shared/styles/mixins/text';

.MonitorSearchDrawer_container {
	background: $white;
	position: absolute;
	z-index: 999;
	top: 0;
	right: 0;
	height: 100svh;
	width: 100vw;
	display: flex;
	flex-direction: column;
	transition: right 0.3s ease-in-out;

	&.isRtl {
		left: 0;
	}
}

.searchContainer {
	position: sticky;
	z-index: 10;
	width: 100%;
	display: flex;
	justify-content: space-between;
	gap: 8px;
	background: $darkBlue;
	padding: 16px;

	.content {
		display: flex;
		align-items: center;
		width: 100%;
		border-radius: 4px;
		border: 1px solid $blue200;
		gap: 4px;
		padding: 4px 8px;
		background: $white;
	}

	.input {
		all: unset;
		width: 100%;
		display: flex;
		align-items: center;
		background: $white;
		@include subtitle-semi();

		&::placeholder {
			@include subtitle-semi($grey500);
		}
	}
}

.searchContentContainer {
	overflow: auto;
	display: flex;
	flex-direction: column;
	gap: 5px;
	padding: 16px;
}
