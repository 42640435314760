@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.monitorFloorPageContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	overflow: hidden;
	height: 100%;

	.contentContainer {
		overflow-y: scroll;
		height: 100%;
		display: flex;
		flex-direction: column;
		width: 100%;
		padding: $monitorHeaderHeight 16px 16px 16px;

		.locationsTitle {
			padding-top: 16px;
			padding-bottom: 8px;
			@include subtitle-semi();
		}
	}
}

.resourcesCountCards {
	position: relative;
	top: 0;
}
