@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/media_queries';

.header {
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-between;

	.headerTextContainer {
		@include subtitle-semi;
		display: flex;
		flex-direction: row;
		gap: 8px;
		align-items: center;
	}

	.clear {
		@include link;
		text-decoration: underline;
	}
}
