@import '@shared/styles/variables';
@import '@shared/styles/mixins/text';

.MonitorSearchCardItem_container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 12px 8px;
	border-bottom: 1px solid $grey100;

	.floorDetails {
		display: flex;
		align-items: center;
		gap: 12px;

		.floorNumberContainer {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 32px;
			height: 32px;
			padding: 8px;
			border-radius: 50%;
			background-color: $grey100;
			unicode-bidi: plaintext;
			@include button-large();

			&.isIcon {
				padding: 8px 4px;
			}
		}
		.floorNick {
			@include body2();
		}
	}
	.countDataAndArrow {
		display: flex;
		align-items: center;
		gap: 2px;

		.fourElements {
			flex-direction: column;
		}

		.countDataContainer {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			padding-inline-start: 16px;
			justify-content: flex-end;
			gap: 16px;

			.elementsPartitionContainer {
				display: flex;
				align-items: center;
				gap: 16px;
			}

			.resourceCountAndIcon {
				display: flex;
				align-items: center;
				gap: 4px;

				.countText {
					@include button-small($grey700);
				}
			}
		}
	}
}
