@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/media_queries';

.selectSection {
	height: 38px;
	cursor: pointer;
	border-radius: 4px;
	border: 2px solid #d5d5d5;
	display: flex;
	align-items: center;
	padding: 8px;
	overflow: hidden;

	gap: 4px;

	& .text {
		@include small($grey400);
		line-height: 16px;
	}

	&.isDisabled {
		background: $grey50;
		border-color: $grey300;
		color: $grey300 !important;
		pointer-events: none;

		input {
			color: $grey300 !important;
		}

		label {
			color: $grey400 !important;
		}

		img,
		svg {
			filter: invert(72%) sepia(19%) saturate(72%) hue-rotate(172deg) brightness(98%) contrast(101%);
		}
	}
}
