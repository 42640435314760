@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.selectedItem {
	background-color: $blue100;
	border-bottom: 2px solid $darkBlue;
	box-sizing: border-box;
}

.tabContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex: 1;
	height: 100%;
	gap: 12px;
	box-sizing: border-box;
	cursor: pointer;
}

.item {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.iconValueContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	@include h6($grey600);
}

.valueText {
	padding-inline-end: 4px;
	margin: 0;
}

.tabLabel {
	margin: 0;
	@include body2();
}

.selectedItemText {
	color: $darkBlue;
}

.itemsContainer {
	display: flex;
	width: 100%;
	justify-content: space-evenly;
}
