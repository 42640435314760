@import '@shared/styles/variables';

.splashWrapper {
	height: 100vh;
	background-color: $primaryColor;
	display: flex;
	align-items: center;
	justify-content: center;
}

.animationItem {
	width: 118px;
}
