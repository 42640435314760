@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';

.widgetCard {
	display: flex;
	flex-shrink: 1;
	min-width: 80px;
}

.sequenceProgressCardsContainer {
	overflow: auto;
	width: 100%;
	flex: 1;
	padding: 0 16px;
}

.floorNick {
	@include small();
}

.count {
	@include small($grey600);
}

.floorTitleSection {
	display: flex;
	justify-content: space-between;
	padding: 16px 8px;
}

.floorAsisWrapper {
	display: flex;
	flex-direction: column;
	padding-bottom: 10px;
}

.floorAsis {
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
}
