@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';

.wrapper {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.title {
	display: flex;
	align-items: center;
	gap: 4px;
	@include body2();
}

.edit {
	cursor: pointer;
	@include link($grey700);
	text-decoration: underline;
}

.isRestrictedText {
	@include body2();
}

.divider {
	width: 100%;
	height: 1px;
	background-color: $grey200;
	margin-top: 16px;
}
