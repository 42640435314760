@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.container {
	display: flex;
	align-items: center;
	height: 38px;
	margin-inline-end: 8px;
}

.projectsImage {
	width: 22px;
	height: 22px;
	margin-right: 16px;
}

.projectSelect {
	& > div {
		background-color: $primaryColor !important;

		& div {
			color: $white !important;
			background-color: $primaryColor !important;
		}

		& > img {
			filter: invert(100%) !important;
		}

		& p {
			color: $white;
		}
	}
}

.listItemContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 6px;
	background-color: $white;

	&:hover {
		background-color: $grey200;
	}
}

.listItem {
	@include body2($primaryColor);
}

.headerTitle {
	width: 100%;
	text-align: end;
	@include h6($white);
	margin-left: 6px;
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
}
