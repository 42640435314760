@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';

.workersOnSiteContainer {
	position: fixed;
	z-index: 999;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100vh;
	width: 100vw;
	background-color: $white;
	padding: 30px 15px;

	.backContainer {
		height: 50px;
		display: flex;
		border-bottom: 1px solid rgba(16, 48, 69, 0.12);
		justify-content: flex-start;
		align-items: center;
		padding: 16px 0;
		color: $primaryColor;

		img {
			margin-inline-end: 7px;
			width: 14px;
			height: 14px;

			&.rotateArrow {
				transform: rotate(180deg);
			}
		}

		.backButton {
			@include body2();
		}
	}

	.scrollContainer {
		overflow: scroll;
	}

	.professionContainer {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 10px 15px;

		.professionDescription {
			color: $primaryColor;
			display: flex;

			img {
				padding: 0 3px;
			}

			.totalWorkers {
				@include subtitle-reg();
			}

			.totalText {
				@include subtitle-reg;
				margin-inline-start: 8px;
			}
		}
	}

	.workersOnSiteCardWrapper {
		margin: 10px;
		border: 1px solid rgba(16, 48, 69, 0.12);
		border-radius: 4px;
	}
}
