@import '../../../styles/variables.scss';

.selectorsContainer {
	display: flex;
	justify-content: space-between;
	height: 50px;
	align-items: center;
	position: sticky;
	background-color: #f1f1f1;
	width: 100%;
	padding: 10px;
}

.workersDataContainer {
	margin: 8px;
	padding: 6px;
	overflow: scroll;
	height: calc(100% - 160px);
}

.workersPageContainer {
	height: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}
