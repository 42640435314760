@import '@shared/styles/variables';
@import 'src/styles/variables';

.container {
	background-color: $white;
	margin-bottom: 8px;
	border: 1px solid $grey200;
	border-radius: 8px;
}

.headers {
	padding: 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.rightSection {
	display: flex;
	align-items: center;
	gap: 8px;
}

.floorsTextWrapper {
	display: flex;
	gap: 8px;
	align-items: center;

	img {
		width: 24px;
		height: 24px;
		filter: brightness(0) saturate(100%) invert(15%) sepia(28%) saturate(1255%) hue-rotate(161deg) brightness(95%)
			contrast(95%);
	}
}

.inactive {
	background: $grey50;
	opacity: 0.5;
}
