@import '@shared/styles/variables';
@import 'src/styles/variables';
@import '@shared/styles/mixins/media_queries';
@import '../../styles/variables';

.pageContainer {
	overflow-y: auto;
	overflow-x: hidden;
	width: 100%;
	display: flex;
	background: $white;
	flex-direction: column;

	&.isRtl {
		left: unset;
	}
}

.disableScroll {
	overflow: initial;
}
