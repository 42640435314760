@import '../../styles/variables';
@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';

.row {
	width: 100%;
	padding: 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-top: 0.5px solid $rowBorderColor;
	font-size: 14px;
}

.professionContainer {
	display: flex;
	white-space: nowrap;
}

.professionHeader {
	@include subtitle-reg;
}

.rightSection {
	display: flex;
	align-items: center;
	gap: 8px;
}
