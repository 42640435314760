@import '@shared/styles/_variables.scss';
@import '@shared/styles/mixins/_text.scss';
@import '@shared/styles/mixins/_media_queries.scss';

.floorsSection {
	display: flex;
	flex-direction: column;

	:global(.MuiFilledInput-underline) {
		border: 1px solid $grey200 !important;
		border-radius: 8px !important;

		&:hover {
			border: 1px solid !important;
		}

		svg {
			display: none;
		}

		input {
			@include subtitle-reg($primaryColor);
			padding-top: unset !important;
			margin: 15px 5px 15px !important;
			text-align: center;
		}
	}
}

.label {
	@include small-regular;
	margin-bottom: 4px;
}

.toText {
	margin: 0 8px;
}

.floorsInputRow {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.floorsInputLabel {
	@include small-regular;
}

.floorsInputLabelMobile {
	font-size: 14px !important;
}

.FirstFloorLabelContainer {
	display: flex;
	flex-direction: column;
	margin-top: 4px;
}

.FirstFloorLabelContainerMobile {
	margin-top: 17px;
}

.fromFloorInput {
	display: flex;
	align-items: center;
}

.row {
	display: flex;
	flex-direction: row;
}

.rowMobile {
	gap: 20px;
}

.toFloorInput {
	display: flex;
	flex-direction: row;
	align-items: baseline;
}

.chipsList {
	display: flex;
	flex-direction: row;
	gap: 8px;
	flex-wrap: wrap;
	margin-top: -8px;

	&.mobile {
		margin-top: 12px;
	}
}

.addFloorButton {
	background-color: transparent;
	cursor: pointer;
	padding: 0 0 0 12px;
	border-radius: 2px;
	width: 30px;
	height: 30px;
	align-self: center;

	&.disable {
		pointer-events: none;
	}
}

.addFloorButtonMobile {
	width: 24px;
	position: relative;
	top: 7px;
	padding: 0;
}

.opacity {
	opacity: 0.5;
}

.addRange {
	@include subtitle-reg($grey500);
	display: flex;
	align-items: center;
	margin-top: 16px;

	img {
		margin-inline-end: 4px;
	}
}
