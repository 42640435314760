@import '@shared/styles/variables';
@import 'src/styles/variables';
@import '@shared/styles/mixins/media_queries';
@import '@shared/styles/mixins/text';

.pageHeader {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 16px 20px;
}

.title {
	@include h5;
	width: 80%;
}

.hr {
	border-top: 1px solid $grey200;
	margin: 0;
}
