@import '@shared/styles/variables';
@import '@shared/styles/mixins/text';

.searchInput {
	height: 32px !important;
}

.bottomSection {
	overflow: scroll;
	margin-top: 16px;

	.tradeName {
		width: 100%;
		text-align: center;
		@include small;
		padding: 12px;
		border-top: 1px solid $grey400;
		border-bottom: 1px solid $grey400;
	}

	.professionItem {
		display: flex;
		padding: 8px;
	}
}
